<template>
    <dashboard-layout>
        <v-row v-if="$auth.user()">
            <v-col> Latest xray: {{ $auth.user().patient.latest_xray }} </v-col>
        </v-row>
        <v-container v-if="xrays.length > 0">
            <v-row v-for="xray in xrays" :key="`xray-` + xray.id">
                <v-col> From {{ xray.created_at }} // data </v-col>
            </v-row>
        </v-container>
        <v-form>
            <v-row>
                <v-col cols="4">
                    <span>Дата</span>
                    <CustomFileUpload
                        label="Загрузить фото"
                        v-model="newXray.file_url"
                        directory="xrays"
                    />
                </v-col>
                <v-col>
                    <h4>Комментарий врача</h4>
                    <v-card rounded="lg">
                        <v-card-text>
                            <v-textarea
                                dense
                                flat
                                solo
                                v-model="newXray.note"
                                placeholder="Оставьте комментарий из Вашей медицинской карты..."
                                elevation="0"
                                color="dark"
                            ></v-textarea>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-row justify="end">
            <v-col cols="auto">
                <CustomButton @click="saveXray">Сохранить</CustomButton>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../../layouts/dashboard/Index";
import Xray from "../../../models/Xray";

export default {
    name: "dashboard-index",
    components: { DashboardLayout },
    computed: {},
    data: function () {
        return {
            xrays: [],
            newXray: {},
        };
    },
    methods: {
        async init() {
            this.xrays = await Xray.get();
        },
        async saveXray() {
            let xray = new Xray(this.newXray);
            await xray.save();
            this.newXray = {};
            await this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped>
</style>
